import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

// sessionStorage に保存する設定
const { persistAtom } = recoilPersist({
  key: 'partyName',
  storage: sessionStorage,  // 必要なら localStorage に変更可能
});

export const partyNameState = atom<string>({
  key: 'partyNameState', // ユニークなキー
  default: '', // 初期値（空の文字列）
  effects_UNSTABLE: [persistAtom], // 永続化設定を適用
});
