import TextField from '@mui/material/TextField';
import styled from "@emotion/styled";
import { ChangeEvent, memo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { partyNameState } from '../../store/partyNameState';

export const PartyNameInput = memo(() => {
    // 飲み会名の状態を管理
    const [partyName, setPartyName] = useRecoilState(partyNameState);

    // テキストフィールドの入力を更新
    const onChangeSetPartyName = (event: ChangeEvent<HTMLInputElement>) => {
        setPartyName(event.target.value);
    };
    
    return (
        <SInputContainer>
            <TextField 
                id="outlined-basic" 
                label="飲み会名" 
                variant="outlined"
                value={partyName} 
                onChange={onChangeSetPartyName}
                fullWidth
            />
        </SInputContainer>
    );
});

const SInputContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 10px;
    width: 100%;
`;
