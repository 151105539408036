import { Suspense  } from "react";
import { RegisteredMemberButton } from "../atoms/button/RegisteredMemberButton";
import ResponsiveDrawer from "../layout/ResponsiveDrawer"
import { InputArea } from "../organisms/InputArea";
import styled from "@emotion/styled"
import { TransitionButton } from "../atoms/button/TransitionButton";
import { useRecoilState } from 'recoil';
import { loadingState } from '../../store/loadingState'; 
import ClipLoader from "react-spinners/ClipLoader";


export const Setting = () => {
  const [ isLoading ] = useRecoilState(loadingState);
    return(
    <ResponsiveDrawer>
      {isLoading ? (
        <StyledLoader>
          <ClipLoader color="#1976d2" size={100} />
      </StyledLoader>
      ) : (
        <>
          <InputArea />
            <RegisteredMemberButton />
          <SInputArea>
            <TransitionButton buttonName="結果" linkName="/party"/>
          </SInputArea>
        </>
      )}
    </ResponsiveDrawer>
    )
}

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8); /* 背景を薄く白くする */
`;

const SInputArea = styled.div`
  display: flex;
  align-items: center;
	justify-content: center;
  padding: 1em;
`