import TextField from '@mui/material/TextField';
import { RadioButton } from "../atoms/button/RadioButton";
import styled from "@emotion/styled"
import { ChangeEvent, memo } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/userState';

export const PersonalInput = memo(() => {
    //recoilによるユーザーリストのグローバルステート
    const [userInfo, setUserInfo] = useRecoilState(userState);

    //テキストの名前をユーザーリストに格納
    const onChangeSetMmberName = (event: ChangeEvent<HTMLInputElement>) => {
        setUserInfo(memberName => ({...memberName, name: event.target.value}));
    };
    
    return(
        <div>
            <SInputContainer>
                <div>
                    <TextField 
                        id="outlined-basic" 
                        label="メンバー名" 
                        variant="outlined"
                        value={userInfo.name} 
                        onChange={onChangeSetMmberName} />
                    <RadioButton />
                </div>
            </SInputContainer>
        </div>
    )
})

const SInputContainer = styled.div`
    display: flex;
    align-items: center;
`