import React, { useRef, useState, useEffect, useCallback } from "react";
import { useRecoilState } from 'recoil';
import { websocketState } from '../../store/websocketState';
import { memberState } from "../../store/memberState";
import { partyNameState } from '../../store/partyNameState';

/**
 * @description マウスポインターが要素と被っているか判定
 */
const isHover = (event: TouchEvent, element: HTMLElement): boolean => {
  // マウスポインターの座標を取得
  const clientX = event.touches[0].clientX;
  const clientY = event.touches[0].clientY;
  // 重なりを判定する要素のサイズと座標を取得
  const rect = element.getBoundingClientRect();
  // マウスポインターが要素と重なっているかを判定する
  return (
    clientY < rect.bottom &&
    clientY > rect.top &&
    clientX < rect.right &&
    clientX > rect.left
  );
};

interface MemberInfo {
  id: number;
  name: string;   
  type: any;
  position: any;
};

// 座標の型
interface Position {
  x: number;
  y: number;
}

// ドラッグ＆ドロップ要素の情報をまとめた型
interface DnDItem<T> {
  value: T; // useDnDSort()の引数に渡された配列の要素の値
  key: number; // 要素と紐づいた一意な文字列
  position: Position; // 要素の座標
  element: HTMLElement; // DOM情報
}

// useRef()で保持するデータの型
interface DnDRef<T> {
  keys: Map<T, number>; // 要素に紐づいたkey文字列を管理するMap
  dndItems: DnDItem<T>[]; // 並び替える全ての要素を保持するための配列
  canCheckHovered: boolean; // 重なり判定ができるかのフラグ
  pointerPosition: Position; // マウスポインターの座標
  dragElement: DnDItem<T> | null; // ドラッグしてる要素
}

// 返り値の型
interface DnDSortResult<T> {
  key: number;
  value: T;
  isTouched: boolean; //Touchしているかの判定
  events: {
    ref: (element: HTMLElement | null) => void;
    onTouchStart: (event: React.TouchEvent) => void;
    onTouchEnd: (event: React.TouchEvent) => void;
    onTouchMove: (event: React.TouchEvent<HTMLElement>) => void;
  };
}

/**
 * @description ドラッグ＆ドロップの並び替え処理を提供します
 */
export const useDnDSort = () => {
  // 描画内容と紐づいているのでuseStateで管理する
  const [memberInfo, setMemberInfo] = useRecoilState(memberState);
  const [ws, setWs] = useRecoilState(websocketState);
  // Touchしているかの判定をuseStateで管理する  
  const [isTouched, setIsTouched] = useState(false);
  // ドラッグ操作を有効にするかどうかのフラグを管理
  const [isDragEnabled, setIsDragEnabled] = useState(true);
  const [partyName] = useRecoilState(partyNameState);
  // 初期チェックで `partyId` が存在するか確認
  useEffect(() => {
    if (!sessionStorage.getItem("partyId")) {
      setIsDragEnabled(false);
    }
  }, []); // 初回レンダリング時にのみ実行

  // const [isPosition, setIsPosition] = useState(false);
  // HTML要素を含むdndItemsも合わせたアイコンの状態をrefで管理する
  const state = useRef<DnDRef<MemberInfo>>({
    dndItems: [],
    keys: new Map(),
    dragElement: null,
    canCheckHovered: true,
    pointerPosition: { x: 0, y: 0 }
  }).current;

  const { dndItems } = state;
  // HTML要素を含むdndItemsの要素順が、recoil管理のmemberInfoの要素順と同じになるようにソートする
  dndItems.sort((a, b) => {
    const indexA = memberInfo.findIndex(item => item.id === a.key);
    const indexB = memberInfo.findIndex(item => item.id === b.key);
    return indexA - indexB;
  });

  // 要素の参照を作成
  const elementRef = useRef<HTMLElement>(null);

  // タッチ開始した時の処理
  const onTouchStart = (event: TouchEvent) => {
    if (!isDragEnabled) {
      // ドラッグ無効化時は何もしない
      return;
    }
    // event.preventDefault();
  };

  // ドラッグ中の処理
  const onTouchMove = (event: TouchEvent) => {
    if (!isDragEnabled) {
      return; 
    }
    // 最初のタッチの情報を取得
    const touch = event.touches[0];
    // タッチされた座標を取得
    const { clientX, clientY } = touch;
    const { dndItems, dragElement, pointerPosition } = state;
    // ドラッグして無ければ何もしない
    if (!dragElement) return;
    // マウスポインターの移動量を計算
    const x = clientX - pointerPosition.x;
    const y = clientY - pointerPosition.y;
    const dragStyle = dragElement.element.style;
    // ドラッグ要素の座標とスタイルを更新
    dragStyle.zIndex = "100";
    dragStyle.cursor = "pointer";
    dragStyle.transition = "none";
    dragStyle.transform = `translate(${x}px,${y}px)`;
    // まだ確認できない場合は処理を終了する
    if (!state.canCheckHovered) return;
    // 確認できないようにする
    state.canCheckHovered = false;
    // 300ms後に確認できるようにする
    setTimeout(() => (state.canCheckHovered = true), 300);
    // ドラッグしている要素の配列の位置を取得
    const dragIndex = dndItems.findIndex(({ key }) => key === dragElement.key);
    // ホバーされている要素の配列の位置を取得
    const hoveredIndex = dndItems.findIndex(
      ({ element }, index) => index !== dragIndex && isHover(event, element)
    );

    // ホバーされている要素があれば、ドラッグしている要素と入れ替える
    if (hoveredIndex !== -1) {
      // カーソルの位置を更新
      state.pointerPosition.x = clientX;
      state.pointerPosition.y = clientY;
      // 要素を入れ替える
      let temp = dndItems[dragIndex];
      dndItems[dragIndex] = dndItems[hoveredIndex];
      dndItems[hoveredIndex] = temp;
      // ドラッグ要素の座標を更新
      dragElement.position = { x, y };
      // 再描画する
      setMemberInfo(dndItems.map((v) => v.value));
    }
  };

  // ドラッグが終了した時の処理
  const onTouchEnd = (event: TouchEvent) => {
    if (!isDragEnabled) {
      // ドラッグ無効化時は何もしない
      return;
    }
    const { dragElement } = state;
    // ドラッグしていなかったら何もしない
    if (!dragElement) return;
    const dragStyle = dragElement.element.style;
    // ドラッグしてる要素に適用していたCSSを削除
    dragStyle.zIndex = "";
    dragStyle.cursor = "";
    dragStyle.transform = "";
    // ドラッグしている要素をstateから削除
    state.dragElement = null;
  };

  const disableScroll = useCallback((event: TouchEvent) => {
    event.preventDefault();
  }, []);

  useEffect(() => {
    if (isDragEnabled) {
      window.addEventListener("touchmove", onTouchMove);
      window.addEventListener("touchend", onTouchEnd);
    }
  
    return () => {
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("touchend", onTouchEnd);
    };
  }, [isDragEnabled, onTouchMove, onTouchEnd]);

  if (!memberInfo || memberInfo.length === 0) {
    return []; // memberInfo が空なら何も表示しない
  }

  return memberInfo.map(
    (value: MemberInfo): DnDSortResult<MemberInfo> => {
      // keyが無ければ新しく作り、あれば既存のkey文字列を返す
      const key = value.id
      // 生成したkey文字列を保存するが、重複して登録されるためコメントアウト
      // state.keys.set(value, key);
    return {
        value,
        key,
        isTouched,
        events: {
          ref: (element: HTMLElement | null) => {
            if (!element) return;
            const { dndItems, dragElement } = state;
            // 位置をリセットする
            element.style.transform = "";
            // 要素の位置を取得
            const { left: x, top: y } = element.getBoundingClientRect();            
            const position: Position = { x, y };
            // 要素が無ければ新しく追加して処理を終わる
            const itemIndex = dndItems.findIndex((item) => item.key === key);
            if (itemIndex === -1) {
              return dndItems.push({ key, value, element, position });
            }
            // ドラッグ要素の時は、ズレを修正する
            if (dragElement?.key === key) {
              // ドラッグ要素のズレを計算する
              const dragX = dragElement.position.x - position.x;
              const dragY = dragElement.position.y - position.y;
              // 入れ替え時のズレを無くす
              element.style.transform = `translate(${dragX}px,${dragY}px)`;
            }

            // ドラッグ要素以外の要素をアニメーションさせながら移動させる
            if (dragElement?.key !== key) {
              const item = dndItems[itemIndex];
              // 前回の座標を計算
              const x = item.position.x - position.x;
              const y = item.position.y - position.y;
              // 要素を前回の位置に留めておく
              element.style.transition = "";
              element.style.transform = `translate(${x}px,${y}px)`;
              // 一フレーム後に要素をアニメーションさせながら元に位置に戻す
              requestAnimationFrame(() => {
                element.style.transform = "";
                element.style.transition = "all 300ms";
              });
            }
            // 要素を更新する
            state.dndItems[itemIndex] = { key, value, element, position };
          },

          // 要素をtouchし始めた時の処理
          onTouchStart: (event: React.TouchEvent) => {
            if (isDragEnabled) {
              // マウスポインターの座標を保持しておく
              state.pointerPosition.x = event.touches[0].clientX;
              state.pointerPosition.y = event.touches[0].clientY;
              // Touchされたらアイコンの色が変わるようにフラグを立てる   
              setIsTouched(true);
              // 他ユーザにURL共有した際にもタッチされたかの状態が共有されるようにsessionストレージに保存
              sessionStorage.setItem("isTouched", "true");
              // Touch中に画面が動かないようにデフォルトのタッチイベントの動作をキャンセル
              window.addEventListener("touchmove", disableScroll, { passive: false });
            }
          },

          // 要素をtouchしている最中の処理
          onTouchMove: (event: React.TouchEvent<HTMLElement>) => {
            if (isDragEnabled) {
              // ドラッグする要素(DOM)
              const element = event.currentTarget;
              // ドラッグしている要素のスタイルを上書き
              element.style.transition = ""; // アニメーションを無効にする
              element.style.cursor = "pointer"; // カーソルのデザインを変更
              // 要素の座標を取得
              const { left: x, top: y } = element.getBoundingClientRect();
              const position: Position = { x, y };
              // ドラッグする要素を保持しておく
              state.dragElement = { key, value, element, position };
            }
          },
          // 要素をtouchし終わった際に処理
          onTouchEnd: (event: React.TouchEvent) => {
            if (isDragEnabled) {
              // Touch終わったらアイコンの色が変わるようにフラグを立てる   
              setIsTouched(false);
              // 他ユーザにURL共有した際にもタッチされたかの状態が共有されるようにsessionストレージに保存
              sessionStorage.setItem("isTouched", "false");
              const documentID = sessionStorage.getItem("partyId")
              // DynamoDBに更新をかける 
              // URLからパーティードキュメントIDを取得
              const pathname = window.location.pathname;
              // /party/以降のパスを取得
              const party_id = pathname.split("/party/")[1];
              if (!ws || ws.readyState !== WebSocket.OPEN) {
                const wsInstance = new WebSocket('wss://rctegwvg44.execute-api.ap-northeast-1.amazonaws.com/dev');
                // 接続が確立されたとき
                wsInstance.onopen = () => {
                  // WebSocketをRecoilの状態にセット
                  setWs(wsInstance);
                  // WebSocketが開いている場合のみ送信
                  if (wsInstance.readyState === WebSocket.OPEN) {
                      wsInstance.send(JSON.stringify({
                        action: 'updateMemberInfo',  // API Gatewayでルートに使用されるアクション
                        type: 'memberInfo',
                        party_id: party_id,  // 生成したroom_idを送信
                        data: memberInfo,  // memberInfoを送信
                        party_name: partyName,
                      }));
                  }
                };
                // エラーハンドリング
                wsInstance.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };
              } else {
                  // 既存のWebSocketが開いている場合のみ送信
                  ws.send(JSON.stringify({
                      action: 'updateMemberInfo',  // API Gatewayでルートに使用されるアクション
                      type: 'memberInfo',
                      party_id: party_id,  // 生成したroom_idを送信
                      data: memberInfo,  // memberInfoを送信
                      party_name: partyName,
                  }));
              }
              // Touch終わったらデフォルトのタッチイベントの動作が出来るようにする
              window.removeEventListener("touchstart", onTouchStart);
              // タッチイベントを解除し、スクロールを元に戻す
              window.removeEventListener("touchmove", disableScroll);
              // windowに登録していたイベントを削除
              window.removeEventListener("touchmove", onTouchMove);
              window.removeEventListener("touchend", onTouchEnd);
            }
          }
        }
      };
    }
  );
};
