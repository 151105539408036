import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useRecoilState } from 'recoil';
import { MemberInfo, memberState } from '../../../store/memberState';
import { websocketState } from '../../../store/websocketState';
import { useNavigate } from 'react-router-dom';
import { loadingState } from '../../../store/loadingState'; 
import { partyNameState } from '../../../store/partyNameState';

//typescriptのためのpropsの型定義
type ButtonType = {
    buttonName: string; 
    linkName: string;
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#1976d2',
        color: theme.palette.common.white,
    },
    buttonText: {
        textTransform: 'lowercase',
        fontSize: '20px',
    },
}));

// 12桁のランダムな数字で構成されたroom_idを生成
const generatePartyId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let roomId = '';
    for (let i = 0; i <20; i++) {
        roomId += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return roomId;
};

export const TransitionButton = (props: ButtonType) => {
    const { buttonName, linkName } = props;
    const [memberInfo, setMemberInfo] = useRecoilState(memberState);
    const classes = useStyles();
    const [ws, setWs] = useRecoilState(websocketState);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useRecoilState(loadingState);
    const [partyName] = useRecoilState(partyNameState);


    // 結果ボタンを押した際の処理
    const handleButtonClick = async (event: any) => {
        if (buttonName === '結果') {
            // 登録ユーザが2人以下の場合に警告
            if( memberInfo.length < 2){
                alert('メンバーを2人以上追加してください。');
                event.stopPropagation();
                return
            }
            let party_id:any
            // party_idを生成
            if(sessionStorage.getItem("partyId")){
                party_id = sessionStorage.getItem("partyId")
            }else{
                party_id = generatePartyId();
                sessionStorage.setItem("partyId", party_id);
            }
            // localStorage から既存のリストを取得（なければ空の配列）
            let party_history = JSON.parse(localStorage.getItem("party_history") || "[]");
            let memberInfoLocalStorage = sessionStorage.getItem("memberInfo")
            // party_id が既存のリストにある場合、それを削除
            party_history = party_history.filter((entry: { party_id: string }) => entry.party_id !== party_id);
            // 現在時刻を取得
            const create_datetime = Date.now();
            // 新しいエントリーを作成
            const newEntry = { party_id, party_name: partyName, create_datetime, memberInfoLocalStorage };
            // 配列の先頭に追加
            party_history.unshift(newEntry);
            // 最大3件まで保存する（4件以上なら最も古いものを削除）
            if (party_history.length > 3) {
                party_history.pop();  // 末尾を削除
            }
            // localStorage に更新後のリストを保存
            localStorage.setItem("party_history", JSON.stringify(party_history));
            // ローディングフラグ開始
            setIsLoading(true);
            // WebSocketがまだ接続されていない場合にのみ接続を開始
            if (!ws || ws.readyState !== WebSocket.OPEN) {
                const wsInstance = new WebSocket('wss://rctegwvg44.execute-api.ap-northeast-1.amazonaws.com/dev');
                // 接続が確立されたとき
                wsInstance.onopen = () => {
                    // WebSocketをRecoilの状態にセット
                    setWs(wsInstance);
                    // WebSocketが開いている場合のみ送信
                    if (wsInstance.readyState === WebSocket.OPEN) {
                        wsInstance.send(JSON.stringify({
                            action: 'sendMemberInfo',  // API Gatewayでルートに使用されるアクション
                            type: 'memberInfo',
                            party_id: party_id,  // 生成したroom_idを送信
                            data: memberInfo,  // memberInfoを送信
                            party_name: partyName,
                            touched: false,
                        }));
                        // ローディングフラグ終了
                        setIsLoading(false);
                        // 画面遷移
                        navigate(`/party/${party_id}`);
                    }
                };
                
                // エラーハンドリング
                wsInstance.onerror = (error) => {
                    console.error('WebSocket error:', error);
                    // ローディングフラグ解除（エラー時）
                    setIsLoading(false); 
                };
            } else {
                // 既存のWebSocketが開いている場合のみ送信
                ws.send(JSON.stringify({
                    action: 'sendMemberInfo',  // API Gatewayでルートに使用されるアクション
                    type: 'memberInfo',
                    party_id: party_id,  // 生成したroom_idを送信
                    data: memberInfo,  // memberInfoを送信
                    party_name: partyName,
                    touched: false,
                }));
                // 画面遷移
                const url =  `/party/${party_id}`; 
                window.location.href = url;
            }
        }
    };
    return(
        <>
            <Button
                variant="contained"
                className={classes.button}
                component={buttonName === '結果' && memberInfo.length < 2 ? 'button' : Link}
                to={buttonName === '結果' ? '#' : linkName}
                TouchRippleProps={{ center: true }} 
                endIcon={<ArrowCircleRightIcon style={{ fontSize: '30px' }} />}
                onClick={handleButtonClick}
            >
                <span className={classes.buttonText}>{buttonName}</span>
            </Button>
        </>
    )
}