import React from "react";
import { Box, Typography, Link } from "@mui/material";
import ResponsiveDrawer from "../layout/ResponsiveDrawer"

export const PrivacyPolicy = () => {
  return (
    <ResponsiveDrawer>
        <Box sx={{ maxWidth: 800, margin: "auto", padding: 3 }}>
        {/* タイトル */}
        <Typography variant="h5" fontWeight="bold" gutterBottom>
            プライバシーポリシー
        </Typography>
        <Typography paragraph>
            本ウェブサイト（以下,「本サービス」といいます。）は，本サービス上で提供するサービスにおける，
            ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
        </Typography>

        {/* 第1条 */}
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            第1条（個人情報）
        </Typography>
        <Typography paragraph>
            「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，
            当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び
            容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
        </Typography>

        {/* 第2条 */}
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            第2条（個人情報の収集方法）
        </Typography>
        <Typography paragraph>
            本サービスは、本サービスが管理するインターネットによるサービスの運営に必要な範囲で、
            本サービスの一般利用者（以下「ユーザー」といいます。）又は本サービスに広告掲載を行う者（以下「掲載主」といいます。）から、
            ユーザー又は掲載主に係る個人情報を取得することがあります。
        </Typography>

        {/* 第3条 */}
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            第3条（個人情報を収集・利用する目的）
        </Typography>
        <Typography component="ol" sx={{ pl: 3 }}>
            <Typography component="li">本サービスサービスの提供・運営のため</Typography>
            <Typography component="li">ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</Typography>
            <Typography component="li">メンテナンス，重要なお知らせなど必要に応じたご連絡のため</Typography>
            <Typography component="li">利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため</Typography>
            <Typography component="li">上記の利用目的に付随する目的</Typography>
        </Typography>

        {/* 第4条 */}
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            第4条（利用目的の変更）
        </Typography>
        <Typography paragraph>
            本サービスは，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，
            個人情報の利用目的を変更するものとします。
            利用目的の変更を行った場合には，変更後の目的について，本サービス所定の方法により，
            ユーザーに通知し，または本サービス上に公表するものとします。
        </Typography>

        {/* 第5条 */}
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            第5条（個人情報の第三者提供）
        </Typography>
        <Typography paragraph>
            本サービスは、法令で定める場合を除き、本人の同意に基づき取得した個人情報を、
            本人の事前の同意なく第三者に提供することはありません。
            なお、本人の求めによる個人情報の開示、訂正、追加若しくは削除又は利用目的の通知については、
            法令に従いこれを行うとともに、ご意見、ご相談に関して適切に対応します。
        </Typography>

        {/* 第6条 */}
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            第6条（プライバシーポリシーの変更）
        </Typography>
        <Typography paragraph>
            本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
            当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本サービスに掲載したときから効力を生じるものとします。
        </Typography>

        {/* 第7条 */}
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            第7条（個人情報の廃棄）
        </Typography>
        <Typography paragraph>
            本サービスは、個人情報の利用目的に照らしその必要性が失われたときは、個人情報を消去又は廃棄するものとし、
            当該消去及び廃棄は、外部流失等の危険を防止するために必要かつ適切な方法により、業務の遂行上必要な限りにおいて行います。
        </Typography>

        {/* お問い合わせ */}
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            お問い合わせ
        </Typography>
        <Typography paragraph>
            本サービスは、個人情報の取扱いに関する担当窓口及び責任者を以下の通り設けます。
        </Typography>
        <Typography>
            メール:{" "}
            <Link href="mailto:yueniwabisuke.tech@gmail.com" color="primary">
            yueniwabisuke.tech@gmail.com
            </Link>
        </Typography>
        </Box>
    </ResponsiveDrawer>
  );
};
