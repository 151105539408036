import * as React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import Input from '@mui/icons-material/Input';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PolicyIcon from '@mui/icons-material/Policy';
import ArticleIcon from '@mui/icons-material/Article';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';

const drawerWidth = 240;

interface Props {
  window?: () => Window;
  children: React.ReactNode;
}

export default function ResponsiveDrawer(props: Props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const move = (nextPage: string) => {
    navigate(nextPage);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem button key={'home'} onClick={() => {move('/home')}}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={'home'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key={'setting'} onClick={() => {move('/setting')}}>
          <ListItemIcon>
            <Input/>
          </ListItemIcon>
          <ListItemText primary={'input'} />
        </ListItem>
      </List>
      <List>
        <ListItem button key={'useage'} onClick={() => {move('/useage')}}>
          <ListItemIcon>
            <PanToolAltIcon/>
          </ListItemIcon>
          <ListItemText primary={'useage'} />
        </ListItem>
      </List>
      <List>
        <ListItem button key={'privacy'} onClick={() => {move('/privacy')}}>
          <ListItemIcon>
            <PolicyIcon/>
          </ListItemIcon>
          <ListItemText primary={'privacy policy'} />
        </ListItem>
      </List>
      <List>
        <ListItem button key={'terms'} onClick={() => {move('/terms')}}>
          <ListItemIcon>
            <ArticleIcon/>
          </ListItemIcon>
          <ListItemText primary={'terms of setvice'} />
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" style={{ textAlign: 'center' }}>
            <Link to="/home" style={{ color: 'white', textDecoration: 'none' }}>Sekigime</Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {children}
      </Box>
      <Box
        component="footer"
        sx={{
          mt: 'auto',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          fontSize: '10px',
          backgroundColor: '#1565c0',
          color: 'white',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '10px' }}>
        <Typography
            variant="body2"
            component={Link}
            to="/useage"
            sx={{
              color: 'white',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            使い方
        </Typography>
        <Typography
            variant="body2"
            component={Link}
            to="/privacy"
            sx={{
              color: 'white',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            プライバシーポリシー
          </Typography>
          <Typography
            variant="body2"
            component={Link}
            to="/terms"
            sx={{
              color: 'white',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            利用規約
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" component="span">
            © 2025 sekigime
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
