import Button from '@mui/material/Button';
import Refresh from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import { memberState } from '../../../store/memberState';
import { websocketState } from '../../../store/websocketState';

export const RandomArangementButton = () => {
  const classes = useStyles();
  const [memberInfo, setMemberInfo] = useRecoilState(memberState);
  const [ws, setWs] = useRecoilState(websocketState);

  // 配列をランダムにシャッフルする関数
  const shuffleArray = (array: any[]) => {
    const shuffled = [...array]; // 元の配列を変更しないようにコピーを作成
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // 0 〜 i のランダムなインデックスを取得
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // 要素を入れ替える
    }
    return shuffled;
  };

  const onClickReload = () => {
    if (memberInfo.length > 1) {
      const shuffledMembers = shuffleArray(memberInfo);
      // DynamoDBに更新をかける 
      // URLからパーティードキュメントIDを取得
      const pathname = window.location.pathname;
      // /party/以降のパスを取得
      const party_id = pathname.split("/party/")[1];
      if (!ws || ws.readyState !== WebSocket.OPEN) {
        const wsInstance = new WebSocket('wss://rctegwvg44.execute-api.ap-northeast-1.amazonaws.com/dev');
        // 接続が確立されたとき
        wsInstance.onopen = () => {
            // WebSocketをRecoilの状態にセット
            setWs(wsInstance);
            // WebSocketが開いている場合のみ送信
            if (wsInstance.readyState === WebSocket.OPEN) {
                wsInstance.send(JSON.stringify({
                    action: 'updateMemberInfo',  // API Gatewayでルートに使用されるアクション
                    type: 'memberInfo',
                    party_id: party_id,  // 生成したroom_idを送信
                    data: shuffledMembers,  // shuffledMembersを送信
                }));
            }
        };
        // エラーハンドリング
        wsInstance.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
      } else {
          // 既存のWebSocketが開いている場合のみ送信
          ws.send(JSON.stringify({
              action: 'updateMemberInfo',  // API Gatewayでルートに使用されるアクション
              type: 'memberInfo',
              party_id: party_id,  // 生成したroom_idを送信
              data: shuffledMembers,  // shuffledMembersを送信
          }));
      }
      setMemberInfo(shuffledMembers); // シャッフルした配列を Recoil にセット
    }
  };
  
  return(
    <Button 
      variant="contained"
      className={classes.button}
      TouchRippleProps={{ center: true }} 
      startIcon={<Refresh />}
      style={{ fontSize: "11px" }} // ここで文字サイズを変更
      onClick={onClickReload}
      >
        ランダム
    </Button> 
    );
};

const useStyles = makeStyles((theme) => ({
  button: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#1976d2',
      color: theme.palette.common.white,
  },
  '&:active': {
    backgroundColor: '#1976d2',  // クリック時（押下時）の色を調整
  },
  buttonText: {
      textTransform: 'lowercase',
      fontSize: '10px',
  },
}));
