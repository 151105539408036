import { useEffect, useState, Suspense } from "react";
import ResponsiveDrawer from '../layout/ResponsiveDrawer';
import { TransitionButton } from '../atoms/button/TransitionButton';
import styled from "@emotion/styled"
import homeImage from '../../sekigimeHome.png';
import drinkImage from '../../drinkImage.png';
import fixImage from '../../gesture01.webp';
import iphoneImage from '../../iphoneImage.png';
import { Paper } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from 'recoil';
import { memberState } from '../../store/memberState';
import { partyNameState } from '../../store/partyNameState';

// PartyHistoryList コンポーネント
const PartyHistoryList = () => {
  const [partyHistory, setPartyHistory] = useState<{party_id: string; party_name: string; create_datetime: number}[]>([]);
  const [memberInfo, setMemberInfo] = useRecoilState(memberState);
  const [partyName, setPartyName] = useRecoilState(partyNameState);

  const navigate = useNavigate();
  useEffect(() => {
      // localStorage からデータを取得
      const storedHistory = JSON.parse(localStorage.getItem("party_history") || "[]");
      setPartyHistory(storedHistory);
  }, []);

  const TransitionFromPartyHistory = (party: any) => {
    sessionStorage.setItem("memberInfo", party.memberInfoLocalStorage);
    sessionStorage.setItem("partyName", JSON.stringify({ partyNameState: party.party_name }));
    sessionStorage.setItem("partyId", party.party_id)
    navigate("/setting");
    // ページ際読み込みしないとrecoilのステート反映が一つ過去のものになるため
    window.location.reload();
  }  

  return (
    <div style={{ width: "100%", margin: "0 auto", marginTop: "10px" }}>
      <h3 style={{ textAlign: "center", color: "#1976d2" }}>飲み会履歴</h3>
      {partyHistory.length === 0 ? (
        <Paper 
          elevation={3} 
          sx={{ 
            width: "100%", // StyledExplanationと統一
            padding: 2, 
            marginBottom: 2, 
            backgroundColor: "#f9f9f9", 
            textAlign: "center",  // 👈 テキストを中央揃え
            display: "flex", // 横並びにする
            alignItems: "center", // 縦方向の中央揃え
            justifyContent: "center", // 👈 水平方向の中央揃え
          }}
        >
          現在は履歴がありません
        </Paper>
      ) : (
        partyHistory.map((party, index) => {
          const date = new Date(party.create_datetime);
          const formattedDate = date.toLocaleDateString("ja-JP", { month: "2-digit", day: "2-digit" });
          const formattedTime = date.toLocaleTimeString("ja-JP", { hour: "2-digit", hour12: false });
          return (
            <Paper 
              key={index} 
              elevation={3} 
              sx={{ 
                width: "100%", // StyledExplanationと統一
                padding: 2, 
                marginBottom: 2, 
                backgroundColor: "#f9f9f9", 
                textAlign: "left",
                display: "flex", // 横並びにする
                alignItems: "center", // 縦方向の中央揃え
                justifyContent: "space-between", // アイコンを右端に配置
              }}
              onClick={() => TransitionFromPartyHistory(party)}
            >
              <div>
                <strong>{party.party_name}</strong> <br />
                {formattedDate} {formattedTime}に作成
              </div>
              <ChevronRightIcon sx={{ color: "#1976d2" }} /> {/* 右矢印アイコンを追加 */}
            </Paper>
          );
        })
      )}
    </div>
  );
};

const Home = () => {
  // sessionStorage.removeItem("new-recoil-persist");
  return (
    <ResponsiveDrawer>
      <StyledContainer>
        <StyledSmollText>
          飲み会や会食での席決めや席変えに
        </StyledSmollText>
        <StyledLargeText>
          Sekigime
        </StyledLargeText>
        <Suspense fallback={<p>Loading...</p>}>
          <img src={homeImage} alt="Sekigime Home" style={{ width: '250px', height: 'auto' }} />
        </Suspense>
        <TransitionButton buttonName="はじめる" linkName='/setting'/>
        <PartyHistoryList />
      </StyledContainer>
      <br />
      <StyledExplanation>
        <br />
        <p style={{ fontWeight: 'bold'}}>Sekigimeの特徴</p>
        <Suspense fallback={<p>Loading...</p>}>
          <img src={drinkImage} alt="Sekigime Home" style={{ width: '200px', height: 'auto' }} />
        </Suspense>
        <h3>簡単に飲み会の席を決定</h3>
        <StyledExplanationText>
          設定したグループ毎に<br/>
          席をランダムに配置することができます。<br/>
        </StyledExplanationText>
        <br/>

          
        <Suspense fallback={<p>Loading...</p>}>
          <img src={fixImage} alt="Fix Home" style={{ width: '200px', height: 'auto' }} />
        </Suspense>
        <h3>配置後の席を微修正</h3>
          <StyledExplanationText>
            ちょっとした変更をしたい場合<br/>
            微修正をすることができます。<br/>
          </StyledExplanationText>
        <br/>


        <Suspense fallback={<p>Loading...</p>}>
          <img src={iphoneImage} alt="Sekigime Home" style={{ width: '200px', height: 'auto' }} />
        </Suspense>
        <h3>登録不要で簡単に利用</h3>
          <StyledExplanationText>
            アプリのインストール不要で<br/>
            ブラウザからすぐに利用できます。<br/>
          </StyledExplanationText>
          <br />
      </StyledExplanation>
    </ResponsiveDrawer>
  );
}
export default Home;


const StyledSmollText = styled.div`
  font-size: 15px;
  font-family: 'Noto Sans Japanese', sans-serif;
  margin-bottom: 10px;
`;

const StyledLargeText = styled.div`
  font-size: 40px;
  font-family: 'Intro', sans-serif;
  margin-bottom: 10px;

`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1976d2;
  flex-direction: column;
`;


const StyledExplanation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1976d2;
  background-color: #E1F5FE;
  flex-direction: column;
`;

const StyledExplanationText = styled.div`
  text-align: center;
`;

const HistoryContainer = styled.div`
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 80%;
  text-align: center;
  background-color: #f9f9f9;
`;