import { Button } from "@material-ui/core";
import LockIcon from '@mui/icons-material/Lock';import { makeStyles } from '@material-ui/core/styles';

export const LockDisplayButton = () => {
    const classes = useStyles();
    
    return (
      <Button 
        variant="contained"
        className={classes.button}
        TouchRippleProps={{ center: true }} 
        startIcon={<LockIcon />}
        style={{ fontSize: "12px" }} // ここで文字サイズを変更
        >共有ページ
      </Button>
    );
};

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#e6892d',
        color: theme.palette.common.white,
    },
    buttonText: {
        textTransform: 'lowercase',
        fontSize: '50px',
    },
}));
