import React, { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import IosShareIcon from '@mui/icons-material/IosShare';
import { LineShareButton, LineIcon } from "react-share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";


export const CopyUrlButton = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  // 現在のページURLを取得
  const url = window.location.href;

  // モーダルを開く
  const handleOpen = () => setOpen(true);
  // モーダルを閉じる
  const handleClose = () => {
    setOpen(false);
    // モーダルを閉じるとコピー状態をリセット
    setCopied(false);
  };
  // URLをコピーする
  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  return (
    <>
      <Button 
        variant="contained"
        className={classes.button}
        TouchRippleProps={{ center: true }} 
        onClick={handleOpen}
        startIcon={<IosShareIcon />}
        style={{ margin: '10px', fontSize: "11px"}}
      >
        共有
      </Button>

      {/* 共有用モーダル */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>リンクを共有</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {/* LINE 共有ボタン */}
          <LineShareButton url={url} className={classes.fullWidth}>
            <Button 
              variant="contained" 
              className={classes.lineButton}
              startIcon={<LineIcon size={24} round />}
              fullWidth
            >
              LINEで共有
            </Button>
          </LineShareButton>
          {/* URL コピー ボタン */}
          <Button 
            variant="contained" 
            className={classes.copyButton}
            onClick={handleCopy}
            startIcon={<ContentCopyIcon />}
            fullWidth
          >
            {copied ? "コピーしました" : "URLをコピー"}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#1976d2",
    color: theme.palette.common.white,
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    padding: "20px",
  },
  fullWidth: {
    width: "100%",
  },
  lineButton: {
    backgroundColor: "#06C755",
    color: theme.palette.common.white,
  },
  copyButton: {
    backgroundColor: "#1976d2",
    color: theme.palette.common.white,
  },
}));