import { AddButton } from "../atoms/button/AddButton"
import { PersonalInput } from "../molecules/PersonalInput"
import { PartyNameInput } from "../molecules/PartyNameInput"
import styled from "@emotion/styled"
import Paper from '@mui/material/Paper';


export const InputArea = () => {
    return(
        <>
            <SInputArea>
                <PartyNameInput />
            </SInputArea>
            <br/>
            <Paper
                sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0.5,
                }}
                component="ul"
            >
                <SInputArea>
                    <PersonalInput />
                    <AddButton />
                </SInputArea>
            </Paper>
        </>
    )
}


const SInputArea = styled.div`
    display: flex;
    align-items: center;
	justify-content: center;
`