import React, { memo, useState, useEffect, useCallback } from "react";
import ResponsiveDrawer from "../layout/ResponsiveDrawer"
import { useDnDSort } from "../templates/useDnDSort";
import { useRecoilState } from "recoil";
import { memberState } from "../../store/memberState";
import styled from "@emotion/styled"
import { RandomArangementButton } from "../atoms/button/RandomArangementButton";
import { BackButton } from "../atoms/button/BackButton";
import NameIcon from "../templates/NameIcon";
import {CopyUrlButton} from "../atoms/button/CopyUrlButton";
import { websocketState } from '../../store/websocketState';
import { LockDisplayButton } from "../atoms/button/LockDisplayButton";
import ClipLoader from "react-spinners/ClipLoader";
import { loadingState } from "../../store/loadingState";
import { useRef } from "react";
import { partyNameState } from '../../store/partyNameState';

const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
  // 配列の長さが異なる場合、不一致とする
  if (arr1.length !== arr2.length) return false;

  // 配列の順序を含めて各要素を比較
  return arr1.every((item1, index) => {
    const item2 = arr2[index];
    // オブジェクトのプロパティをすべて比較する
    return Object.keys(item1).every(
      (key) => item1[key] === item2[key]
    );
  });
};

type Style<T extends HTMLElement> = React.HTMLAttributes<T>["style"];
/**
 * @description ドラッグ＆ドロップ並び替えサンプルのコンポーネント
 */
export const Result = () => {
  const [memberInfo, setMemberInfo] = useRecoilState(memberState);
  const [partyName, setPartyName] = useRecoilState(partyNameState);

  // URLからパーティードキュメントIDを取得
  const pathname = window.location.pathname;
  // /party/以降のパスを取得
  const party_id = pathname.split("/party/")[1];
  const [ws, setWs] = useRecoilState(websocketState);
  // Loadingフラグを管理するフラグ
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  // Doorのタッチ前初期座標
  const [entryPosition, setEntryPosition] = useState({ x: 0, y: 0 });
  // Doorのタッチ時の初期座標（タッチ操作の座標計算の運動量計算で使用）
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  // ドアの座標が変更されるのを監視する
  const entranceRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // 共有ユーザが送付されたURLからDynamoDBにデータ取得（WebSocketがまだ接続されていない場合にのみ接続を開始）
    if (!ws || ws.readyState !== WebSocket.OPEN) {
      // ローディングフラグ開始
      setIsLoading(true);
      const wsInstance = new WebSocket('wss://rctegwvg44.execute-api.ap-northeast-1.amazonaws.com/dev');
      // 接続が確立されたとき、reload時は③
      wsInstance.onopen = () => {
        // WebSocketをRecoilの状態管理に保存
        setWs(wsInstance);
        // WebSocket経由でDynamoDBデータ取得リクエストを送信、reload時は④
        wsInstance.send(JSON.stringify({
          action: 'getMemberInfo',  // DynamoDBからデータ取得するためのアクション
          party_id: party_id  // クエリするparty_idを指定
        }));
        wsInstance.send(JSON.stringify({
          action: 'getDoorPosition',  // DynamoDBからデータ取得するためのアクション
          party_id: party_id  // クエリするparty_idを指定
        }));
      };
      wsInstance.onmessage = (event) => {
        if(event.data === ""){
          return
        }
        const data = JSON.parse(event.data);
        // 受信：DynamoDB更新するgetMemberInfo APIもしくはupdateMemberInfo API叩かれた際に全connectionIDが最新情報を受信
        if (data.type === "get_member_info" || data.type === "update_member_info") {
          // DynamoDBから受信したmemberInfo vs reactでrecoilで管理しているmemberInfoが異なれば、DynamoDBから受信したデータでrecoilを更新する
          if (areArraysEqual(data.member_info, memberInfo)) {
            setIsLoading(false);
          } else {
            // 必要に応じて状態やUIを更新
            try {
              // memberInfo = data.member_info
              setMemberInfo(data.member_info);
              setPartyName(data.party_name);
              // ローディングフラグ終了
              setIsLoading(false);
            } catch (error) {
              console.error('Error updating memberInfo:', error);
              // ローディングフラグ解除（エラー時）
              setIsLoading(false); 
            }
          }
        // 受信：DynamoDB更新するupdateDoorPosition API叩かれた際に全connectionIDが最新情報を受信
        }else if(data.type === "get_door_position" || data.type === "update_door_position"){
          try {
            setEntryPosition(data.door_position);
          } catch (error) {
            console.error('Error updating entryPosition:', error);
          }
        }else{
          return;
        }
      };
      wsInstance.onerror = (error) => {
          console.error('WebSocket error:', error);
          // ローディングフラグ解除（エラー時）
          setIsLoading(false); 
      };
      wsInstance.onclose = () => {
          console.log('WebSocket connection closed');
          // ローディングフラグ解除（エラー時）
          setIsLoading(false); 
      };
    }
  }, [ws, setWs, setMemberInfo]);

  // ドアの座標（entryPosition）が変更されるたびにアイコンが動くようにする
  useEffect(() => {
    if (entranceRef.current) {
      entranceRef.current.style.transform = `translate(${entryPosition.x}px, ${entryPosition.y}px)`;
    }
  }, [entryPosition]); 

  //名前リストを種類ごとにソート
  const results = useDnDSort();

  const disableScroll = useCallback((event: TouchEvent) => {
    event.preventDefault();
  }, []);

  const handleTouchStart = (event: React.TouchEvent<HTMLElement>) => {
    if (!sessionStorage.getItem("partyId")) {
      // ドラッグ無効化時は何もしない
      return; 
    }
    // タッチが始まったときの処理
    const touch = event.touches[0];
    const { clientX, clientY } = touch;
    setStartPosition({ x: clientX, y: clientY });
    // Touch中に画面が動かないようにデフォルトのタッチイベントの動作をキャンセル
    window.addEventListener("touchmove", disableScroll, { passive: false });
    // スクロールを停止
    document.body.style.overflow = 'hidden';
  };
  
  const handleTouchMove = (event: React.TouchEvent<HTMLElement>) => {
    if (!sessionStorage.getItem("partyId")) {
      // ドラッグ無効化時は何もしない
      return; 
    }
    // タッチ中に移動したときの処理
    const touch = event.touches[0];
    const { clientX, clientY } = touch;
    // マウスポインターの移動量を計算
    const x = clientX - startPosition.x + entryPosition.x;
    const y = clientY - startPosition.y + entryPosition.y;
    event.currentTarget.style.transform = `translate(${x}px,${y}px)`;
  };
  
  const handleTouchEnd = (event: React.TouchEvent<HTMLElement>) => {
    if (!sessionStorage.getItem("partyId")) {
      // ドラッグ無効化時は何もしない
      return; 
    }
    // タッチが終了したときの処理
    // タッチ終了時に現在の位置を初期位置として設定
    setEntryPosition({
      x: event.currentTarget.style.transform ? parseInt(event.currentTarget.style.transform.split('(')[1].split('px')[0]) : 0,
      y: event.currentTarget.style.transform ? parseInt(event.currentTarget.style.transform.split(',')[1].split('px')[0]) : 0,
    });
    // DynamoDBにドアの座標をアップデート
    if (!ws || ws.readyState !== WebSocket.OPEN) {
      const wsInstance = new WebSocket('wss://rctegwvg44.execute-api.ap-northeast-1.amazonaws.com/dev');
      // 接続が確立されたとき、reload時は③
      wsInstance.onopen = () => {
        // WebSocketをRecoilの状態管理に保存
        setWs(wsInstance);
        // WebSocketが開いている場合のみ送信
        if (wsInstance.readyState === WebSocket.OPEN) {
          wsInstance.send(JSON.stringify({
            action: 'updateDoorPosition',
            type: 'doorPosition',
            party_id: party_id,
            data: {
              x: event.currentTarget.style.transform ? parseInt(event.currentTarget.style.transform.split('(')[1].split('px')[0]) : 0,
              y: event.currentTarget.style.transform ? parseInt(event.currentTarget.style.transform.split(',')[1].split('px')[0]) : 0,
            },
          }));
        }
      };
      // エラーハンドリング
      wsInstance.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    } else {
      // 既存のWebSocketが開いている場合のみ送信
      ws.send(JSON.stringify({
        action: 'updateDoorPosition',
        type: 'doorPosition',
        party_id: party_id,
        data: {
          x: event.currentTarget.style.transform ? parseInt(event.currentTarget.style.transform.split('(')[1].split('px')[0]) : 0,
          y: event.currentTarget.style.transform ? parseInt(event.currentTarget.style.transform.split(',')[1].split('px')[0]) : 0,
        },
      }));
      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    }
    // Touch中に画面が動かないようにデフォルトのタッチイベントの動作をキャンセル
    window.removeEventListener("touchmove", disableScroll);
    // スクロールを有効に戻す
    document.body.style.overflow = 'auto';
  };
  
  return (
    <ResponsiveDrawer>
      <SbodyStyle>
        <div style={buttonContainerStyle}>
          <BackButton/>
          {/* メインユーザの画面のみ（partyId が sessionstorageに格納されてる場合のみ）、「ランダム」を表示 */}
          {sessionStorage.getItem("partyId") && 
            <RandomArangementButton />
          }
          {/* 共有ユーザの画面のみ（partyId が sessionstorageに格納されていない場合のみ）、「共有ページ」を表示 */}
          {!sessionStorage.getItem("partyId") && 
            <LockDisplayButton/>
          }
          <CopyUrlButton />
        </div>
        {isLoading ? (
          <StyledLoader>
            <ClipLoader color="#1976d2" size={100} />
          </StyledLoader>
        ) : (
          <div style={containerStyle}>
              {results.map((item, index) => (
                index % 2 === 0
                  ?<div key={item.key} style={{ ...imageCardStyle, opacity: item.isTouched ? 0.5 : 1 }} {...item.events}>
                      <NameIcon userInfo={item.value} />
                  </div>
                  :<div key={item.key} style={{ ...imageCardStyleRight, opacity: item.isTouched ? 0.5 : 1 }} {...item.events}>
                      <NameIcon userInfo={item.value} />
                  </div>
                ))}
              <div style={table}></div>
          </div>
        )}
        <div 
          ref={entranceRef}
          style={{ ...entranceStyle }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        ></div>
      </SbodyStyle>
    </ResponsiveDrawer>
  );
};

const SbodyStyle = styled.div`
    height: 100vh,
    display: flex,
    alignItems: center,
    justifyContent: center,
`
const containerStyle: Style<HTMLDivElement> = {
  width: "100%",
  backgroundColor: "antiquewhite",
  maxWidth: "350px",
  maxHeight: "auto",
  height: "auto",
  margin: "auto",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "space-between"
};

const imageCardStyle: Style<HTMLDivElement> = {
  cursor: "grab",
  userSelect: "none",
  width: "60px",
  height: "60px",
  overflow: "hidden",
  borderRadius: "100px",
  display: "flex",
  // デフォルトの不透明度を設定
  opacity: 1, 
};

const imageCardStyleRight: Style<HTMLDivElement> = {
  cursor: "grab",
  userSelect: "none",
  width: "60px",
  height: "60px",
  overflow: "hidden",
  borderRadius: "100px",
  display: "flex",
  alignSelf: "flex-end",
  position:"relative",
  bottom:"60px",
  // デフォルトの不透明度を設定
  opacity: 1, 
};

const table: React.CSSProperties = {
  width: "60%",
  height: "100%",
  left: "20%",
  position: "absolute",
  backgroundColor: "rgba(49, 23, 1, 0.623)",
  background: `repeating-radial-gradient(circle at -1000% 0%, rgba(116, 77, 48, 0.7), #573216 7.5%, rgba(116, 77, 48, 0.9) 10%), repeating-radial-gradient(circle at -1000% 0%, #573216, #573216 0.1%, #744d30 0.4%, #744d30 0.5%)`
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
};

const entranceStyle: React.CSSProperties = {
  // 他のスタイルプロパティ...
  width: '70px',
  height: '70px',
  backgroundImage: `url(/door.png)`, // 画像のパス
  backgroundSize: 'cover', // 画像サイズの調整方法
  // 他のスタイルプロパティ...
};

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8); /* 背景を薄く白くする */
`;


// const circle: React.CSSProperties = {
//   width: "60%",
//   height: "100%",
//   left: "20%",
//   position: "absolute",
//   backgroundColor: "rgba(49, 23, 1, 0.623)",
//   borderRadius: "50%",
//   background: `repeating-radial-gradient(circle at -1000% 0%, rgba(116, 77, 48, 0.7), #573216 7.5%, rgba(116, 77, 48, 0.9) 10%), repeating-radial-gradient(circle at -1000% 0%, #573216, #573216 0.1%, #744d30 0.4%, #744d30 0.5%)`
// };


// const image: React.CSSProperties = {
//   width: '400px',
//   aspectRatio: '1',
//   borderRadius: '50%',
//   border: 'dashed 1px #777',
//   margin: '50px',
//   position: 'relative',
// };


// const imageItem: React.CSSProperties = {
//   width: '80px',
//   height: '80px',
//   borderRadius: '50%',
//   background: '#777',
//   fontSize: '32px',
//   fontWeight: '700',
//   color: '#fff',
//   lineHeight: '80px',
//   textAlign: 'center',
//   position: 'absolute',
//   WebkitTransform: 'translate(-50%, -50%)',
//   transform: 'translate(-50%, -50%)',
// };


