import { atom } from "recoil";

const getRandomIntInclusive = (min: number, max:number) => {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);
}

export type UserInfo = {
    id: number;
    name: string;   
    type: any;
    position: any;
};

export const userState = atom({
    key: 'userState',
    default: { 
        id: getRandomIntInclusive(1,10000),
        name: '',
        type: 'primary',
        position: null },
});
