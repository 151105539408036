import React, { useState, useEffect, FC } from "react";
import iconMaker, { IconOption } from "./iconMaker";

// ユーザー情報の型定義
interface UserInfo {
  id: number;
  name: string;
  type: string;
}

// NameIconコンポーネント
const NameIcon: FC<{ userInfo: UserInfo; iconOption?: IconOption }> = ({ userInfo, iconOption }) => {
  const [iconData, setIconData] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;

    const generateIcon = async () => {
      setLoading(true);
      try {
        const data = await iconMaker(userInfo, iconOption);
        if (isMounted) {
          setIconData(data);
        }
      } catch (error) {
        console.error("Error generating icon:", error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    generateIcon();

    return () => {
      isMounted = false;
    };
  }, [userInfo, iconOption]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!iconData) {
    return <p>Failed to load icon</p>;
  }

  return <img alt={`${userInfo.name}'s icon`} src={iconData} />;
};

export default NameIcon;
