import React from "react";
import { Box, Typography } from "@mui/material";
import ResponsiveDrawer from "../layout/ResponsiveDrawer"

export const TermsOfService = () => {
  return (
    <ResponsiveDrawer>
        <Box sx={{ maxWidth: 800, margin: "auto", padding: 3 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
            利用規約
        </Typography>
        <Typography paragraph>
            この利用規約（以下，「本規約」といいます。）は，このウェブサイト「Sekigime」上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。
            登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
        </Typography>
        
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            第1条（適用）
        </Typography>
        <Typography component="ol" sx={{ pl: 3 }}>
            <Typography component="li">本規約は、ユーザーと本サービスとの間の本サービスの利用に関わる一切の関係に適用されるものとします。</Typography>
            <Typography component="li">本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。</Typography>
            <Typography component="li">本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定の規定が優先されるものとします。</Typography>
        </Typography>
        
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            第2条（禁止事項）
        </Typography>
        <Typography paragraph>
            ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
        </Typography>
        <Typography component="ol" sx={{ pl: 3 }}>
            {["法令または公序良俗に違反する行為", "犯罪行為に関連する行為", "本サービスの内容等、本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為", "ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為", "本サービスによって得られた情報を商業的に利用する行為", "本サービスのサービスの運営を妨害するおそれのある行為", "不正アクセスをし，またはこれを試みる行為", "他のユーザーに関する個人情報等を収集または蓄積する行為", "不正な目的を持って本サービスを利用する行為", "本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為", "他のユーザーに成りすます行為", "本サービスが許諾しない本サービス上での宣伝，広告，勧誘，または営業行為", "面識のない異性との出会いを目的とした行為", "本サービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為", "その他，本サービスが不適切と判断する行為"].map((text, index) => (
            <Typography component="li" key={index}>{text}</Typography>
            ))}
        </Typography>
        
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            第3条（本サービスの提供の停止等）
        </Typography>
        <Typography paragraph>
            本サービスは，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができます。
        </Typography>
        <Typography component="ol" sx={{ pl: 3 }}>
            {["本サービスにかかるコンピュータシステムの保守点検または更新を行う場合", "地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合", "コンピュータまたは通信回線等が事故により停止した場合", "その他，本サービスが本サービスの提供が困難と判断した場合"].map((text, index) => (
            <Typography component="li" key={index}>{text}</Typography>
            ))}
        </Typography>
        
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            第4条（利用制限）
        </Typography>
        <Typography paragraph>
            本サービスは，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができます。
        </Typography>
        <Typography component="ol" sx={{ pl: 3 }}>
            {["本規約のいずれかの条項に違反した場合", "その他，本サービスが本サービスの利用を適当でないと判断した場合"].map((text, index) => (
            <Typography component="li" key={index}>{text}</Typography>
            ))}
        </Typography>
        
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            第5条（保証の否認および免責事項）
        </Typography>
        <Typography paragraph>
            本サービスは，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
        </Typography>
        
        <Typography variant="h6" fontWeight="bold" gutterBottom>
            第6条（サービス内容の変更等）
        </Typography>
        <Typography paragraph>
            本サービスは，ユーザーへの事前の告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。
        </Typography>
        </Box>
    </ResponsiveDrawer>
  );
};
