import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";


export type value = {
    id: number;
    name: string;   
    type: any;
    position: any;
};

export type MemberInfo = {
    id: number;
    name: string;   
    type: any;
    position: any;
};

const { persistAtom } = recoilPersist({
	key: "memberInfo",
	storage: sessionStorage
});

export const memberState = atom<MemberInfo[]>({
    key: 'memberState',
    default: [],
    effects_UNSTABLE: [persistAtom]
});