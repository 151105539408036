import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";

export const BackButton = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    const handleGoToSetting = () => {
      if(sessionStorage.getItem("partyId")){
        navigate("/setting");
      }else{
        // 共有ユーザの画面のみ（partyId が {sessionstorageに格納されていない場合のみ）「戻る」ボタン押下で警告モーダル表示
        setOpenModal(true);
      }
    };

    return (
      <>
        <Button 
          variant="contained"
          className={classes.button}
          TouchRippleProps={{ center: true }} 
          startIcon={<ArrowCircleLeftIcon />}
          style={{ fontSize: "11px" }} // ここで文字サイズを変更
          onClick={handleGoToSetting}
          >戻る
        </Button>
        {/* モーダル */}
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
          <DialogContent>
            <DialogContentText>
              現在の共有ページから離れますがよろしいでしょうか？
              <br/>
              再度共有ページを表示する場合は、共有されたリンクから表示してください。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenModal(false)}
              color="secondary"
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button 
              onClick={() => {
                setOpenModal(false);
                navigate("/setting");
              }} 
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
};

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#1976d2',
        color: theme.palette.common.white,
    },
    buttonText: {
        textTransform: 'lowercase',
        fontSize: '50px',
    },
}));
