import React from "react";
import ResponsiveDrawer from "../layout/ResponsiveDrawer"
import {Suspense} from "react";
import styled from "@emotion/styled"
import memberInput from '../../memberInput.png';
import memberPositionFix from '../../memberPositionFix.gif';
import doorPositionFix from '../../doorPositionFix.gif';
import shareURL from '../../shareURL.png';

export const Useage = () => {
  return (
    <ResponsiveDrawer>
         <StyledExplanation>
            <h2 style={{ fontWeight: 'bold'}}>Sekigimeの使い方</h2>
            <br/>
            <h3>1. メンバーを入力する</h3>
                <Suspense fallback={<p>Loading...</p>}>
                    <img src={memberInput} alt="Sekigime Home" style={{ width: '200px', height: 'auto' }} />
                </Suspense>
                <br/>
                <StyledExplanationText>
                    参加メンバーを男女で分けたい場合は2色、上司や学年を区別したい場合等は最大5色で区別して、メンバーを入力します。
                </StyledExplanationText>
            <br/>
            <h3>2. 席を入れ替える</h3>
                <Suspense fallback={<p>Loading...</p>}>
                    <img src={memberPositionFix} alt="Fix Home" style={{ width: '200px', height: 'auto' }} />
                </Suspense>
                <br/>
                <StyledExplanationText>
                    全体を入れ替えたい時はランダムボタン、1人ずつ微調整したい時はアイコン操作をし、好きな配置にします。
                </StyledExplanationText>
            <br/>
            <h3>3. 入り口を配置する</h3>
                <Suspense fallback={<p>Loading...</p>}>
                <   img src={doorPositionFix} alt="Sekigime Home" style={{ width: '200px', height: 'auto' }} />
                </Suspense>
                <br/>
                <StyledExplanationText>
                    ドアアイコンを操作して、実際の会場に合わせて入口を配置します。    
                </StyledExplanationText>
            <br/>
            <h3>4. メンバーに共有する</h3>
                <Suspense fallback={<p>Loading...</p>}>
                    <img src={shareURL} alt="Sekigime Home" style={{ width: '200px', height: 'auto' }} />
                </Suspense>
                <br/>
                <StyledExplanationText>
                    共有ボタンを押して、配置した席のURLをメンバーに共有します。    
                </StyledExplanationText>
            <br/>
        </StyledExplanation>
    </ResponsiveDrawer>
  );
};

const StyledExplanation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1976d2;
  background-color: #E1F5FE;
  flex-direction: column;
`;

const StyledExplanationText = styled.div`
  text-align: center;
`;