// Icon作成オプション
export type IconOption = {
    size: number,      // iconのサイズ
    foreColor: string, // フォントの色
    backColor: string, // 背景色
    fontScale: number, // フォントのサイズ(iconのサイズに対する比率(0.7程度が適当))
    fontFamily: string,// フォントの種類
  };

  // 返り値の型
  interface userInfo{
    id: number;
    name: string;   
    type: any;
  }
  
  // Icon作成デフォルト値
  const defaultValue: IconOption = {
    size: 120,
    foreColor: '#424242',
    backColor: 'alice blue',
    fontScale: 0.7,
    fontFamily: 'sans-serif'
  };

  // MemberInfoのtypeをCSSで適用できる色に変換
  const convertStringToColor = (type:string) => {
    let color = '';
    switch (type) {
      case 'error':
        color = '#FF8A80';
        break;
      case 'primary':
        color = '#82B1FF';
        break;
      case 'success':
        color = '#1DE9B6';
        break;
      case 'warning':
        color = '#EEFF41';
        break;
      case 'default':
        color = '#757575';
        break;
      default:
        break;
    }
    return color;
  }

  
  const iconMaker = async(userInfo: userInfo, option?: IconOption): Promise<string> => {
    // デフォルト値をoptionのプロパティーで(あれば)上書き
    const opt = {...defaultValue, ...option};
    const [width, height] = [opt.size, opt.size];
  
    // 描画用のCanvasを用意する
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    if (!context) throw new Error('could not get context.');
  
    // スペースを含む場合、splitして最初の2文字を結合する '山田 太郎' -> '山太'。
    // スペースを含まない場合、先頭2文字にする '山田太郎' -> '山田'
    const splitName = userInfo.name.split(' ');
    const abbrev = (splitName.length >= 2 ? splitName[0].substring(0,1) + splitName[1].substring(0,1) : userInfo.name.substring(0, 2));
    
    // canvasを円形にくり抜く(clip)
    context.beginPath();
    context.ellipse(width / 2, height / 2, width / 2, height / 2, 0, 0, Math.PI * 2);
    context.closePath();
    context.clip();

    // Icon背景色を各アイコンの色にする
    opt.backColor = convertStringToColor(userInfo.type);

    // 背景を塗りつぶす
    context.fillStyle = opt.backColor;
    context.fillRect(0, 0, width * 2, height * 2);
  
    // 名前を描画
    context.fillStyle = opt.foreColor;
    context.font = `bold ${height * opt.fontScale}px ${opt.fontFamily}`;
  
    // 文字の中心を合わせる
    const mesure = context.measureText(abbrev);
    const centerX = width - mesure.width > 0 ? (width - mesure.width) / 2 : 0;
    const centerY = (height + mesure.actualBoundingBoxAscent + mesure.actualBoundingBoxDescent) / 2;
    context.fillText(abbrev, centerX, centerY, width);
  
    // Canvasの画像をオブジェクトURLへ変換(imgタグのhrefにセットすると画像を表示できる)
    const imageUrl = canvas.toDataURL();

    // Canvasを破棄する
    canvas.remove();

    
    return imageUrl;
  };
  
  export default iconMaker;
  